"use client";

import React, { useEffect } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { getNookies } from "@/helpers/getCookies";
import { fetchRouting } from "@/helpers/protectRoute";
import { Flex } from "@/styles/styled/styled";
import { APP_TITLE } from "../constants/window";
import { preloadIcons } from "@/utils/helper";
import { apiEndpoints } from "@/constants/api";
import { getFetcher } from "@/services/onboarding";
import { GENERAL_CONFIG } from "@/constants/general";
import { ROLE_ACCESS } from "@/constants/rbac";
import { mapRoutes } from "@/constants/landingPage";
import { useSelector } from "react-redux";

const Signup = dynamic(() => import("@/components/signup"));
const FivexLoader = dynamic(() => import("@/components/loader/FivexLoader"));

type HomePageProps = {
  token: string;
};

export async function getServerSideProps(context: object) {
  const cookies: { accessToken?: string } = (await getNookies(context)) as {
    accessToken?: string;
  };
  const { accessToken } = cookies || {};

  return {
    props: { token: accessToken || null }, // Pass props to your page here
  };
}

const HomePage: React.FC<HomePageProps> = ({ token }) => {
  const [isSignup, setIsSignup] = React.useState<boolean>(false);
  const [loginDataState, setLoginDataState] = React.useState<any>(null);

  const router = useRouter();

  const companyData = useSelector(
    (state: { user: any }) => state.user.companyDetails,
  );

  useEffect(() => {
    // Preload the images of the platform for faster loading
    preloadIcons();
  }, []);

  const getUserData = async () => {
    const res = await getFetcher(apiEndpoints.userData);
    const defaultLandingPage =
      res.data?.workspace?.settings?.defaultLandingPage;
    const entitlements = res.data?.entitlements;
    const isAccess = GENERAL_CONFIG.find((item) => {
      return item.value === defaultLandingPage;
    })?.rbac;

    if (res?.status === "SUCCESS") {
      if (isAccess && entitlements?.[isAccess] !== ROLE_ACCESS.NO_ACCESS) {
        sessionStorage.setItem("defaultLandingPage", defaultLandingPage);
        router.push(mapRoutes[defaultLandingPage]);
      } else {
        sessionStorage.setItem("defaultLandingPage", "IDE");
        router.push("ide");
      }
    }
  };

  useEffect(() => {
    if (loginDataState && !companyData) {
      getUserData();
    }
  }, [loginDataState]);

  useEffect(() => {
    if (token) {
      fetchRouting(router, {
        onComplete: (status: string) => {
          // callback function
          setLoginDataState(status);
        },
        onError: () => setIsSignup(true),
      });
    } else {
      setIsSignup(true);
    }
  }, [token]);

  return (
    <>
      <title>{APP_TITLE} - Signup</title>
      {isSignup ? (
        <Signup />
      ) : (
        <Flex height={"100vh"}>
          <FivexLoader />
        </Flex>
      )}
    </>
  );
};

export default HomePage;
